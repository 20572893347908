export const additional = [
"Openlane",
"Yearin",
"Goodsilron",
"Condax",
"Opentech",
"Golddex",
"year-job",
"Isdom",
"Gogozoom",
"Y-corporation",
"Nam-zim",
"Donquadtech",
"Warephase",
"Donware",
"Faxquote",
"Sunnamplex",
"Lexiqvolax",
"Sumace",
"Treequote",
"Iselectrics",
"Zencorporation",
"Plusstrip",
"dambase",
"Toughzap",
"Codehow",
"Zotware",
"Statholdings",
"Conecom",
"Zathunicon",
"Labdrill",
"Ron-tech",
"Green-Plus",
"Groovestreet",
"Zoomit",
"Bioplex",
"Zumgoity",
"Scotfind",
"Dalttechnology",
"Kinnamplus",
"Konex",
"Stanredtax",
"Cancity",
"Finhigh",
"Kan-code",
"Blackzim",
"Dontechi",
"Xx-zobam",
"Fasehatice",
"Hatfan",
"Streethex",
"Inity",
"Konmatfix",
"Bioholding",
"Hottechi",
"Ganjaflex",
"Betatech",
"Domzoom",
"Ontomedia",
"Newex",
"Betasoloin",
"Mathtouch",
"Rantouch",
"Silis",
"Plussunin",
"Plexzap",
"Finjob",
"Xx-holding",
"Scottech",
"Funholding",
"Sonron",
"Singletechno",
"Rangreen",
"J-Texon",
"Rundofase",
"Doncon",
"Donware",
"Faxquote",
"Sunnamplex",
"Lexiqvolax",
"Sumace",
"Treequote",
"Iselectrics",
"Zencorporation",
"Plusstrip",
"dambase",
"Toughzap",
"Codehow",
"Zotware",
"Statholdings",
"Conecom",
"Zathunicon",
"Labdrill",
"Ron-tech",
"Green-Plus",
"Groovestreet",
"Zoomit",
"Bioplex",
"Zumgoity",
"Scotfind",
"Dalttechnology",
"Kinnamplus",
"Konex",
"Stanredtax",
"Cancity",
"Finhigh",
"Kan-code",
"Blackzim",
"Dontechi",
"Xx-zobam",
"Fasehatice",
"Hatfan",
"Streethex",
"Inity",
"Konmatfix",
"Bioholding",
"Hottechi",
"Ganjaflex",
"Betatech"
]

export const name = [
"Dom",
"Mamusia",
"Dom Babci",
"Dom Babci 2",
"Do pracy",
"Brent Partington",
"Naoma Bugg",
"Yen Stradford",
"Melany Strack",
"Wilhemina Ponder",
"Justina Brashears",
"Mollie Geren",
"Lise Mcquillen",
"Bart Duquette",
"Joy Losey",
"Omega Yurick",
"Prince Morrisey",
"Sarita Masse",
"Eldon Curatolo",
"Cleveland Lander",
"Vernita Jang",
"Deana Wark",
"Elijah Flippo",
"Kecia Mattinson",
"Tyree Pham",
"Betty Every",
"Lecia Varnadoe",
"Yolando Mcduffy",
"Lillia Baez",
"Harvey Hirschman",
"Xiao Simmon",
"Marc Oconnor",
"Efrain Deniz",
"Dakota Goering",
"Lila Tupper",
"Maranda Plowman",
"Elease Dora",
"Briana Delpino",
"Clarence Riser",
"Justin Poe",
"Osvaldo Atkins",
"Bo Deblois",
"Ramiro Piper",
"Ailene Gosney",
"Blythe Lenahan",
"Ryan Pfarr",
"Ericka Dryer",
"Cheryle Washburn",
"Alyce Quinteros",
"Malcolm Fielden",
"Zachery Siegrist",
"Maryland Ludlum",
"Zona Wixon",
"Karissa Balbuena",
"Micaela Hultgren",
"Brent Partington",
"Naoma Bugg",
"Yen Stradford",
"Melany Strack",
"Wilhemina Ponder",
"Justina Brashears",
"Mollie Geren",
"Lise Mcquillen",
"Bart Duquette",
"Joy Losey",
"Omega Yurick",
"Prince Morrisey",
"Sarita Masse",
"Eldon Curatolo",
"Cleveland Lander",
"Vernita Jang",
"Deana Wark",
"Elijah Flippo",
"Kecia Mattinson",
"Tyree Pham",
"Betty Every",
"Lecia Varnadoe",
"Yolando Mcduffy",
"Lillia Baez",
"Harvey Hirschman",
"Xiao Simmon",
"Marc Oconnor",
"Efrain Deniz",
"Dakota Goering",
"Lila Tupper",
"Maranda Plowman",
"Elease Dora",
"Briana Delpino",
"Clarence Riser",
"Justin Poe",
"Osvaldo Atkins",
"Bo Deblois",
"Ramiro Piper",
"Ailene Gosney",
"Blythe Lenahan",
"Ryan Pfarr",
"Ericka Dryer",
"Cheryle Washburn",
"Alyce Quinteros",
"Malcolm Fielden",
"Zachery Siegrist",
"Maryland Ludlum",
"Zona Wixon",
"Karissa Balbuena",
"Micaela Hultgren",
"Brent Partington",
"Naoma Bugg",
"Yen Stradford",
"Melany Strack",
"Wilhemina Ponder",
"Justina Brashears",
"Mollie Geren",
"Lise Mcquillen",
"Bart Duquette",
"Joy Losey",
"Omega Yurick",
"Prince Morrisey",
"Sarita Masse",
"Eldon Curatolo",
"Cleveland Lander",
"Vernita Jang",
"Deana Wark",
"Elijah Flippo",
"Kecia Mattinson",
"Tyree Pham",
"Betty Every",
"Lecia Varnadoe",
"Yolando Mcduffy",
"Lillia Baez",
"Harvey Hirschman",
"Xiao Simmon",
"Marc Oconnor",
"Efrain Deniz",
"Dakota Goering",
"Lila Tupper",
"Maranda Plowman",
"Elease Dora",
"Briana Delpino",
"Clarence Riser",
"Justin Poe",
"Osvaldo Atkins",
"Bo Deblois",
"Ramiro Piper",
"Ailene Gosney",
"Blythe Lenahan",
"Ryan Pfarr",
"Ericka Dryer",
"Cheryle Washburn",
"Alyce Quinteros",
"Malcolm Fielden",
"Zachery Siegrist",
"Maryland Ludlum",
"Zona Wixon",
"Karissa Balbuena",
"Micaela Hultgren"
];