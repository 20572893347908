export const customers = [
    ["customer:08f4b497-d8e3-4ac9-ac66-ccb10cf8a373",	"335993",	"Lester",	"Laviolette",	"1",	"NEW"],
    ["customer:08f7ab34-8f1e-4cf5-91c0-99083e0cafb1",	"371710",	"Magdalena",	"Mayfield",	"1",	"NEW"],
    ["customer:08f94504-725b-42d6-8a5f-018e0d2ff660",	"125825",	"Lorita",	"Litton",	"1",	"NEW"],
    ["customer:08fc4732-188b-4a40-b506-c8a601b11cba",	"100637",	"Madie",	"Mauch",	"1",	"NEW"],
    ["customer:08fd03ec-7be2-4a93-8a0d-26a2b245be3f",	"016200",	"Emma",	"Eberle",	"1",	"NEW"],
    ["customer:08ff6b8e-4eb4-4d69-8e1d-cc381c37b62a",	"400824",	"Larissa",	"Labar",	"1",	"NEW"],
    ["customer:08ff82a5-d76e-4172-92c0-06eecbf13394",	"403356",	"Reyna",	"Raposa",	"1",	"NEW"],
    ["customer:0900ca0a-1901-490a-b020-183f78eb641d",	"425408",	"Howard",	"Hollen",	"1",	"NEW"],
    ["customer:0904fd10-0907-43b5-92bc-17c603982d46",	"356643",	"Ngan",	"Nichol",	"1",	"NEW"],
    ["customer:090609dd-1ea2-4aff-b518-0aa266942c67",	"187417",	"Jame",	"Jakubowski",	"1",	"NEW"],
    ["customer:090e7b7f-fe96-4ab8-9f43-6de0287b19e7",	"364359",	"Georgette",	"Goris",	"1",	"NEW"],
    ["customer:0912b776-7316-400e-b18b-48f043ac117e",	"059303",	"Luanna",	"Lockett",	"1",	"NEW"],
    ["customer:091c1a89-1fe7-4d31-b7c5-48919b4de3e4",	"518840",	"Grady",	"Greeno",	"1",	"NEW"],
    ["customer:091c4695-ccdc-4d20-b62a-98fb670ca65f",	"292675",	"Cordia",	"Creamer",	"1",	"NEW"],
    ["customer:091db471-2915-4b77-9af1-dae5458f37a7",	"729208",	"Gianna",	"Goupil",	"1",	"NEW"],
    ["customer:0922bfef-2bce-42ac-ae81-536b0271a218",	"754015",	"Tommy",	"Tsosie",	"1",	"NEW"],
    ["customer:0922ffc0-21b9-4991-9b89-b7517425dfb6",	"666663",	"Barrie",	"Buettner",	"1",	"NEW"],
    ["customer:092379e4-544d-48e6-bb9e-cc5134fc50e5",	"347173",	"Marlana",	"Mingo",	"1",	"NEW"],
    ["customer:0925c51e-5111-470d-8dc6-4ea5cfe54039",	"080201",	"Imogene",	"Ipock",	"1",	"NEW"],
    ["customer:092afb7d-602c-4c12-be43-b1a70ede778d",	"491203",	"Wendy",	"Winton",	"1",	"NEW"],
    ["customer:092eb249-24fb-433b-b025-b9cba8ab026f",	"642119",	"Aura",	"Arambula",	"1",	"NEW"],
    ["customer:0932d830-3e87-42c8-b024-1b846c4fb95c",	"677470",	"Nannette",	"Navarra",	"1",	"NEW"],
    ["customer:093fe3e7-95dd-493d-890e-57ef5be12bb5",	"562759",	"Earlene",	"Eure",	"1",	"NEW"],
    ["customer:094b9407-e6e9-452c-9ae4-72c5d4ba0fcf",	"383828",	"Sally",	"Streich",	"1",	"NEW"],
    ["customer:095036c0-3e28-4c5e-b025-e8313b48b528",	"737696",	"Lajuana",	"Lejeune",	"1",	"NEW"],
    ["customer:0954351a-28a9-472c-a888-479b43d8bc46",	"239533",	"Antonina",	"Amey",	"1",	"NEW"],
    ["customer:09546ff4-fc69-42aa-8833-ed08585b5478",	"267077",	"Shoshana",	"Slayton",	"1",	"NEW"],
    ["customer:096891fe-5f4f-4631-a16e-5360f899127c",	"915879",	"Kena",	"Kilman",	"1",	"NEW"],
    ["customer:09738018-c8c7-4124-83f4-871ec5bb7918",	"386613",	"Tameika",	"Tutson",	"1",	"NEW"],
    ["customer:09853c9c-becc-4a10-b2df-2af3a0baf3bb",	"113117",	"Delila",	"Ducan",	"1",	"NEW"],
    ["customer:0985adc8-fee5-461c-9ddb-2c25aef13019",	"026437",	"Matt",	"Meier",	"1",	"NEW"],
    ["customer:0985d27c-986d-4c4d-9b5a-d11cba1ba578",	"415852",	"Lacie",	"Laing",	"1",	"NEW"],
    ["customer:0989cacb-efed-46e0-999e-27bc09d1b399",	"291559",	"Esmeralda",	"Eddie",	"1",	"NEW"],
    ["customer:098e1927-fbf8-423b-b4c7-127658bf6c16",	"410083",	"Barrett",	"Belcher",	"1",	"NEW"],
    ["customer:098ee03c-0c1d-4c74-99ee-4debc777cb6d",	"062124",	"Colin",	"Cummins",	"1",	"NEW"],
    ["customer:099c723a-2ff7-439c-ad89-0855ae3599b8",	"117405",	"Federico",	"Ferro",	"1",	"NEW"],
    ["customer:09a34f7e-7ec1-47fa-a48a-16741070422e",	"396249",	"Salvatore",	"Schild",	"1",	"NEW"],
    ["customer:09b2536b-efe1-47d2-9bcd-ef7d9ec354de",	"532165",	"Marlo",	"Mill",	"1",	"NEW"],
    ["customer:09b5a05a-8f53-4ec7-b400-6df3c1ef10f4",	"083565",	"Chanell",	"Cardoza",	"1",	"NEW"],
    ["customer:09bd77f8-2bbd-4f93-8a96-e0fa4b8791fd",	"599070",	"Cody",	"Conyer",	"1",	"NEW"],
    ["customer:09bef906-8b3c-4db3-bf79-aec83f2a4e1e",	"284621",	"Chia",	"Cada",	"1",	"NEW"],
    ["customer:09c5f3ae-7d01-4167-bd17-c46cad571edf",	"387422",	"Tifany",	"Tune",	"1",	"NEW"],
    ["customer:09c804d2-0625-4726-abaf-909b76354a59",	"379036",	"Kallie",	"Koop",	"1",	"NEW"],
    ["customer:09d20f1c-3cbb-43df-8816-b3429f9f9f07",	"372771",	"Debroah",	"Dinatale",	"1",	"NEW"],
    ["customer:09d2785a-7bbc-47ca-acc8-36a9c4cc003b",	"016619",	"Tyree",	"Tillmon",	"1",	"NEW"],
    ["customer:09dd0e94-0258-4e9a-bf69-fbc8d7bfcaba",	"721588",	"Herlinda",	"Hector",	"1",	"NEW"],
    ["customer:09e038cf-178b-41b1-ac70-5806f58db0da",	"972462",	"Sanford",	"Shackelford",	"1",	"NEW"],
    ["customer:09e139be-b49e-47c2-b3e8-afae606c0f07",	"013346",	"Griselda",	"Grieves",	"1",	"NEW"],
    ["customer:09e24cbf-a3fa-4569-aed8-a11fe541ae38",	"949642",	"Yun",	"Yurick",	"1",	"NEW"],
    ["customer:09e95dba-4a88-463e-a5b6-650672e43c39",	"975513",	"Albert",	"Ammann",	"1",	"NEW"],
    ["customer:09ed6046-46e2-492a-8785-4b58c4c1e659",	"681809",	"Shakita",	"Sisson",	"1",	"NEW"],
    ["customer:09ef42a7-642e-4960-895a-9f35a6fac8bb",	"769766",	"Rosaline",	"Riney",	"1",	"NEW"],
    ["customer:09f17b51-4044-4a2f-9957-c6be77384010",	"010654",	"Juliet",	"Jorden",	"1",	"NEW"],
    ["customer:09f4827c-0a5c-455b-9cc5-c734f9fb48d7",	"299473",	"Janel",	"Joachim",	"1",	"NEW"],
    ["customer:09f6107c-0ac7-4422-9542-392e4cea35fc",	"024651",	"Jamika",	"Jantz",	"1",	"NEW"],
    ["customer:09fd9110-6ecb-4f15-a135-2a3db874e133",	"109797",	"Jo",	"Jacome",	"1",	"NEW"],
    ["customer:0a15477e-9101-4a27-8deb-cd53ce22a24d",	"577237",	"Samantha",	"Sigg",	"1",	"NEW"],
    ["customer:0a17ff0c-636c-4e34-ac26-b2743b87fc4d",	"591871",	"Delicia",	"Dupuy",	"1",	"NEW"],
    ["customer:0a1c6d65-196a-43a9-ba5f-d71b543aa32e",	"267183",	"Nettie",	"Neil",	"1",	"NEW"],
    ["customer:0a23dde7-838d-44b1-9e62-0f9727e24162",	"662838",	"Salena",	"Skeeter",	"1",	"NEW"],
    ["customer:0a28328e-4b0b-4b69-b0fe-b522df81ea03",	"295723",	"Alyssa",	"Asbury",	"1",	"NEW"],
    ["customer:0a31794e-f060-4093-a00f-3b1bf6a30601",	"705239",	"Roslyn",	"Rezac",	"1",	"NEW"],
    ["customer:0a335421-a0c1-4eb0-8509-fa73a7c53a71",	"227249",	"Salvatore",	"Schild",	"1",	"NEW"],
    ["customer:0a47f1e8-749b-48fd-ac29-df44cf7aa512",	"404821",	"Leonel",	"Looney",	"1",	"NEW"],
    ["customer:0a550519-8260-4287-93ce-63ba063f149f",	"278267",	"Marita",	"Mascia",	"1",	"NEW"],
    ["customer:0a557b7f-ab0d-4a88-80f4-899d7c505e92",	"098203",	"Wendy",	"Winton",	"1",	"NEW"],
    ["customer:0a5a1bc6-21f3-4cf5-aafb-656747db66bd",	"582368",	"Katelyn",	"Kirsh",	"1",	"NEW"],
    ["customer:0a65eb0d-b5a8-42a0-a8dd-eadd82a28671",	"522101",	"Eve",	"Edmond",	"1",	"NEW"],
    ["customer:0a6822fa-e8c0-4d72-8b65-54fa3971f2ca",	"923127",	"Cleo",	"Cappelli",	"1",	"NEW"],
    ["customer:0a6e3b07-cc0d-4cfe-b26c-6f76606e7b48",	"255309",	"Damian",	"Dezern",	"1",	"NEW"],
    ["customer:0a78c84b-11fb-4b66-9e86-320f03426791",	"987752",	"Kareem",	"Kolstad",	"1",	"NEW"],
    ["customer:0a8114c7-7021-4687-8d2e-9001168e3684",	"670742",	"Kirsten",	"Koon",	"1",	"NEW"],
    ["customer:0a82c688-a510-455d-82a5-10dafec321ac",	"415301",	"Heath",	"Harshman",	"1",	"NEW"],
    ["customer:0a86313e-4711-49d8-91f3-b6e910ed1185",	"543711",	"Shalon",	"Sever",	"1",	"NEW"],
    ["customer:0a87c10e-b8f2-408d-95f3-0b20b86253d4",	"390489",	"Theresa",	"Troup",	"1",	"NEW"],
    ["customer:0a96b285-b9b7-4717-91c9-a3b4c6f10714",	"782677",	"Aliza",	"Avery",	"1",	"NEW"],
    ["customer:0a9aea6b-884a-440c-aecc-3d0a53bb600e",	"534718",	"Elisha",	"Ernest",	"1",	"NEW"],
    ["customer:0a9c2b2d-8a35-4659-9b59-cec5af70574c",	"457826",	"Moises",	"Mckeever",	"1",	"NEW"],
    ["customer:0aa8e9f7-608d-4fac-a92e-52db12dbbddd",	"018691",	"Karmen",	"Kuhl",	"1",	"NEW"],
    ["customer:0aaf8f88-cf1b-4a17-ac0d-ea3dbc965a02",	"103899",	"Douglas",	"Davis",	"1",	"NEW"],
    ["customer:0ababe87-717c-4cf5-9888-1647258f469d",	"469419",	"Ashely",	"Almond",	"1",	"NEW"],
    ["customer:0ad5599b-e04b-4e34-9774-538a20cf2ece",	"117691",	"Karmen",	"Kuhl",	"1",	"NEW"],
    ["customer:0adc3ac8-aac5-4e6c-bc20-5d95f00bf246",	"376665",	"Jonell",	"Jamieson",	"1",	"NEW"],
    ["customer:0adc3d3f-3946-4c24-bdc4-916416bc8f2f",	"520445",	"Lavonna",	"Logue",	"1",	"NEW"],
    ["customer:0aea1f29-e230-4834-b7b1-cdf2d5dcf728",	"374002",	"Millard",	"Mirsky",	"1",	"NEW"],
    ["customer:0aed247e-93f0-4293-9ed5-92973e3ca64f",	"238403",	"Marisa",	"Meals",	"1",	"NEW"],
    ["customer:0af1c35e-8e08-467c-9afb-a15771b48587",	"502626",	"Margaretta",	"Maston",	"1",	"NEW"],
    ["customer:0af1f518-d840-4738-af2b-10c8618863b1",	"424639",	"Lindsy",	"Lightsey",	"1",	"NEW"],
    ["customer:0af3947b-48da-4517-89a6-6a570f2c1886",	"018177",	"Josie",	"Jelks",	"1",	"NEW"],
    ["customer:0afb22d5-7106-4bdd-bdaf-8d73fdc28519",	"004866",	"Rona",	"Ricketts",	"1",	"NEW"],
    ["customer:0b0055f6-586e-422f-aeeb-74bd0567bf91",	"135053",	"Nydia",	"Norberg",	"1",	"NEW"],
    ["customer:0b0e6c97-b28d-4be6-96b3-2940d584ac61",	"635707",	"Cythia",	"Cadwallader",	"1",	"NEW"],
    ["customer:0b197b2b-a46e-4c8d-aa95-4fa015fb8d48",	"659297",	"Leonore",	"Loughran",	"1",	"NEW"],
    ["customer:0b1d6636-e3d9-41fd-a5a5-6f3d33f9d631",	"197649",	"Tennie",	"Tuggle",	"1",	"NEW"],
    ["customer:0b1db61b-d413-411b-8111-06faf0ed640d",	"333757",	"Dianne",	"Dilday",	"1",	"NEW"],
    ["customer:0b1e6fc1-c377-484a-8ec3-48eee973dedb",	"033918",	"Tamara",	"Treece",	"1",	"NEW"],
    ["customer:0b20d5bd-61ed-4637-aa02-11328266f0fa",	"330439",	"Ignacia",	"Italiano",	"1",	"NEW"],
    ["customer:0b21e467-6810-43f4-92a4-1b32109c50d6",	"385386",	"Shonda",	"Sievers",	"1",	"NEW"],
    ["customer:0b288282-2401-48eb-b035-750017eaace6",	"096543",	"Lucie",	"Lundstrom",	"1",	"NEW"],
    ["customer:0b2f77b1-d68b-4590-a1bc-bdd887887a2f",	"083066",	"Eneida",	"Ehrhart",	"1",	"NEW"],
    ["customer:0b34152c-e5a7-4101-9aff-7bcd8178998a",	"040499",	"Skye",	"Shatzer",	"1",	"NEW"],
    ["customer:0b4d4f61-25c4-4a39-93bd-7409fc0c68a4",	"001000",	"Inga",	"HRYNIEWIECKA",	"6","9501130",	"NEW"],
    ["customer:0b50b790-5e62-4120-bb47-3955d5958554",	"235535",	"Bernarda",	"Burritt",	"1",	"NEW"],
    ["customer:0b51bbe0-48e2-4114-b431-6926f1e19f10",	"288011",	"Rhonda",	"Rodenberg",	"1",	"NEW"],
    ["customer:0b53f01d-eba0-402e-a40a-db213cb4d5ea",	"599972",	"Malissa",	"Mccrystal",	"1",	"NEW"],
    ["customer:0b631952-1bf0-4abe-9669-242d863cc7b4",	"413458",	"Corrie",	"Chisum",	"1",	"NEW"],
    ["customer:0b63c52f-a6ac-4a26-86df-b4a363e8bba8",	"036214",	"Sheron",	"Saenger",	"1",	"NEW"],
    ["customer:0b63dad6-0c28-43f4-829f-c7d4f73445dc",	"955288",	"Lieselotte",	"Lehmkuhl",	"1",	"NEW"],
    ["customer:0b69b9cf-ff38-475a-bbad-73ce0eda3dca",	"631260",	"Lilli",	"Leone",	"1",	"NEW"],
    ["customer:0b702ec3-88a6-4a88-a929-83ab6ceb8309",	"944163",	"Leesa",	"Loveland",	"1",	"NEW"],
    ["customer:0b754b24-d686-46c6-a52c-fd00675eab4c",	"356601",	"Veola",	"Vallejos",	"1",	"NEW"],
    ["customer:0b7d5657-107b-46a7-9d4a-dc153f48fe2e",	"236051",	"Pandora",	"Popovich",	"1",	"NEW"],
    ["customer:0b7e0983-3d2e-470f-b790-b26a1c1a6840",	"007437",	"Matt",	"Meier",	"1",	"NEW"],
    ["customer:0b8bc429-beb0-4481-bf4d-add4ba69f2c8",	"424946",	"Shalanda",	"Schug",	"1",	"NEW"],
    ["customer:0b8d52dc-f85b-4f53-a1e4-7b707c2e3384",	"785097",	"Jerri",	"Jardin",	"1",	"NEW"],
    ["customer:0b8fdce5-355e-4121-926f-fd5af7542e52",	"674197",	"Lucille",	"Limberg",	"1",	"NEW"],
    ["customer:0b9fecb3-f6e4-4186-b10d-7a6976f47c9c",	"149801",	"Kanisha",	"Kean",	"1",	"NEW"],
    ["customer:0ba22ecd-02fe-4529-80ee-8d52ae3c52f3",	"532769",	"Sallie",	"Sisson",	"1",	"NEW"],
    ["customer:0ba2afb2-1b94-4406-b061-26e8c83b0f3a",	"273209",	"Maurine",	"Manzanares",	"1",	"NEW"],
    ["customer:0ba3e0e0-2ef8-4ce9-8e09-75fb8756f81e",	"093113",	"Kori",	"Kroenke",	"1",	"NEW"],
    ["customer:0babf10e-cc8f-45b1-b473-b9beef43473c",	"288117",	"Delila",	"Ducan",	"1",	"NEW"],
    ["customer:0bb421f2-6d25-4243-a735-451e7ce140c4",	"949122",	"Charlena",	"Cobb",	"1",	"NEW"],
    ["customer:0bb9a5b0-0925-464a-bedd-5f0381ac7e93",	"681575",	"Samira",	"Sand",	"1",	"NEW"],
    ["customer:0bc01742-72c4-4f73-8946-9a11eb56abd7",	"656717",	"Alissa",	"Anguiano",	"1",	"NEW"],
    ["customer:0bc8a987-3c33-4875-ac72-3a056f614dfc",	"618240",	"Alia",	"Acres",	"1",	"NEW"],
    ["customer:0bcf0410-923b-47f9-a168-7f5426a970d4",	"406328",	"Ginette",	"Givens",	"1",	"NEW"],
    ["customer:0bd1b653-f16a-4945-9ed9-380cd8b5dbe8",	"520161",	"Ericka",	"Eckhardt",	"1",	"NEW"],
    ["customer:0bd7db0a-54ac-4906-9079-3507c8543d6b",	"594455",	"Danyelle",	"Demarco",	"1",	"NEW"],
    ["customer:0beec298-6c16-43aa-8866-f8510f2da977",	"637265",	"Shameka",	"Steves",	"1",	"NEW"],
    ["customer:0bf70ef8-58bc-4b11-827d-05103ef7bc8d",	"510650",	"Claudia",	"Callihan",	"1",	"NEW"],
    ["customer:0c03bea6-52df-47c0-b230-78f05a0c51ce",	"403359",	"Georgette",	"Goris",	"1",	"NEW"],
    ["customer:0c08e79c-c62f-477f-a108-24426217d768",	"330247",	"Rachel",	"Rayner",	"1",	"NEW"],
    ["customer:0c0d386c-ee3b-4403-b806-37cded84a347",	"163527",	"April",	"Aguiar",	"1",	"NEW"],
    ["customer:0c1a2bbf-28da-4420-bfbe-22ed3cfb7ab8",	"360889",	"Ana",	"Andreotti",	"1",	"NEW"],
    ["customer:0c1dfad3-d0d9-4ebc-b59b-3c0a505b840c",	"025098",	"Erma",	"Eagar",	"1",	"NEW"],
    ["customer:0c217fbf-6a09-4586-a6a8-0f87922f845d",	"926943",	"Elton",	"Eno",	"1",	"NEW"],
    ["customer:0c294fc8-4087-40c2-a10d-a07183f58dcb",	"793780",	"Loma",	"Lair",	"1",	"NEW"],
    ["customer:0c2a1b21-d3c2-4095-98f8-661f457613f0",	"340505",	"Sirena",	"Sawtelle",	"1",	"NEW"],
    ["customer:0c31d996-467c-491a-ab34-d448625c2084",	"373779",	"Rosette",	"Renz",	"1",	"NEW"],
    ["customer:0c3435a6-2c51-4e88-9051-ffd0f8546688",	"165439",	"Ignacia",	"Italiano",	"1",	"NEW"],
    ["customer:0c442f3b-d4b9-46c8-9e82-a195d8dd47b5",	"147469",	"Brock",	"Burbage",	"1",	"NEW"],
    ["customer:0c445c0a-72bf-4341-bbec-f6f59789a7a2",	"679628",	"Crystal",	"Champagne",	"1",	"NEW"],
    ["customer:0c4b9876-2662-4ff6-85d7-2d0a6b7115dd",	"287391",	"Carylon",	"Carwell",	"1",	"NEW"],
    ["customer:0c4c94c5-2ace-40a5-b9f6-5d14042e667e",	"005302",	"Lorriane",	"Lamorte",	"1",	"NEW"],
    ["customer:0c522808-2fe1-464d-bb8d-cd6362c88d7c",	"640314",	"Chara",	"Cissell",	"1",	"NEW"],
    ["customer:0c6173f8-941f-44fe-9c17-69e644d8d51d",	"293503",	"Elizbeth",	"Eason",	"1",	"NEW"],
    ["customer:0c73fd06-e1c4-425c-ade0-7fa9811dffb0",	"496245",	"Xiao",	"Xiong",	"1",	"NEW"],
    ["customer:0c74468b-0e50-43ee-98e1-35816ad05f15",	"284437",	"Matt",	"Meier",	"1",	"NEW"],
    ["customer:0c75e35c-41aa-4dd0-9140-475faf4c7751",	"414130",	"Courtney",	"Carlo",	"1",	"NEW"],
    ["customer:0c7c954f-a2ab-47f0-bcd6-53b8f4d14ad5",	"136143",	"Yolando",	"Yerger",	"1",	"NEW"],
    ["customer:0c7f08f8-94ab-420a-9b8b-fff450f66a46",	"378857",	"Aurea",	"Arel",	"1",	"NEW"],
    ["customer:0c7f0f08-8733-487b-8b93-2669037fdf3b",	"329717",	"Alissa",	"Anguiano",	"1",	"NEW"]
];